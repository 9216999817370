<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <s-toolbar
            :color="'#244093'"
            dark
            label="Reporte Corto Largo"
            class="tran"
          ></s-toolbar>
          <v-row style="margin: auto">
            <v-card width="100%">
              <v-row
                justify="start"
                style="margin-top: 4px; margin-left: 10px; margin: auto"
              >
                <v-col sm="6" cols="6" lg="4" md="4">
                  <s-select-definition
                    :def="1215"
                    v-model="objMounth"
                    return-object
                    label="Seleccione periodo"
                  ></s-select-definition>
                </v-col>
                <v-col cols="6" lg="2" md="2" class="mt-3 pl-0">
                  <v-btn
                    width="100%"
                    rounded
                    :color="'info'"
                    small
                    @click="run()"
                    >Buscar</v-btn
                  >
                </v-col>
                <v-col cols="6" lg="2" md="2" class="mt-3 pl-0">
                  <v-btn
                    width="100%"
                    rounded
                    :color="'error'"
                    small
                    @click="downloadReport()"
                    :disabled="disabledBtnDownload"
                    >Descargar</v-btn
                  >
                </v-col>
              </v-row>
            </v-card>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-tabs v-model="currentItem">
        <v-tab href="#Dashboard"> Dashboard</v-tab>
        <v-tab href="#ReportCustomer"> Reporte Corto Largo</v-tab>
        <v-tab href="#ReportByYear"> Reporte por Año</v-tab>
      </v-tabs>

      <v-tabs-items v-model="currentItem">
        <v-tab-item :value="'Dashboard'">
          <clc-short-long-dashboard
            :items="dataDashboard"
            :objDrilldown="objDrilldown"
          ></clc-short-long-dashboard>
        </v-tab-item>
        <v-tab-item :value="'ReportCustomer'">
          <v-col cols="12">
            <v-row>
              <v-col cols="12">
                <gral-table :headers="headers" :items="items"></gral-table>
              </v-col>
            </v-row>
          </v-col>
        </v-tab-item>
        <v-tab-item :value="'ReportByYear'">
          <v-col cols="12">
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="6">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Cuadrante"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <gral-table
                  :headers="headersByYear"
                  :items="byYear"
                  :propsdinamic="{ search, 'group-by': 'Año' }"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-tab-item>
      </v-tabs-items>

      <v-dialog
        v-model="processing"
        :persistent="messageProcessing.length == 0"
        width="400"
      >
        <v-card color="primary" dark>
          <v-card-text v-if="messageProcessing.length == 0">
            Por favor espere
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
          <v-card-text v-else>
            <div class="text--white pt-4">
              Error al realizar busqueda <br />
              {{ messageProcessing }}
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";
import ClcShortLongDashboard from "./ClcShortLongDashboard.vue";
import GralTable from "../GralTable.vue";

export default {
  components: {
    ClcShortLongDashboard,
    GralTable,
  },
  data() {
    return {
      currentItem: "tab-Funciones",
      items: [],
      headers: [],
      report: {},
      byYear: [],
      headersByYear: [],
      processing: false,
      messageProcessing: "",
      dataDashboard: [],
      objDrilldown: [],
      search: "",
      objMounth: null,
      disabledBtnDownload: true,
      period: "",
    };
  },
  methods: {
    run() {
      this.runView();
      this.loadByYear();
      this.period = this.objMounth.DedDescription;
      this.disabledBtnDownload = false;
    },
    runView() {
      this.messageProcessing = "";
      this.report.QryParamsArray = "Period";
      this.report.QryParamsArrayValue = `${this.objMounth.DedAbbreviation}`;
      this.report.QryNameProcedure = "ClcReportShortLong_R";
      this.processing = true;
      _sQryConfigurationService
        .querygeneral(this.report, this.$fun.getUserID())
        .then(
          (resp) => {
            if (resp.status == 200) {
              this.dataDashboard = resp.data
                .filter((item) => item.Cuadrante !== "Total")
                .map((item) => ({
                  name: item.Cuadrante,
                  y: item.Total,
                  drilldown: item.Cuadrante,
                }));
              this.items = resp.data;
              this.headers = Object.keys(resp.data[0]).map((item) => ({
                text: item,
                value: item,
                width: "120",
                sortable: false,
              }));
              this.objDrilldown = resp.data
                .filter((item) => item.Cuadrante !== "Total")
                .map((item) => ({
                  name: item.Cuadrante,
                  id: item.Cuadrante,
                  data: Object.entries(item).filter(
                    (obj) => obj[0] === "Corto" || obj[0] === "Largo"
                  ),
                }));
              this.processing = false;
            }
          },
          (e) => {
            this.messageProcessing = e.response.data.Message;
            this.processing = true;
          }
        );
    },
    loadByYear() {
      const report = {};
      this.messageProcessing = "";
      report.QryParamsArray = "Period";
      report.QryParamsArrayValue = `${this.objMounth.DedAbbreviation}`;
      report.QryNameProcedure = "ClcReportShortLongBase_R";
      this.processing = true;
      _sQryConfigurationService
        .querygeneral(report, this.$fun.getUserID())
        .then((resp) => {
          if (resp.status == 200) {
            this.headersByYear = Object.keys(resp.data[0]).map((item) => ({
              text: item,
              value: item,
              width: "120",
              sortable: false,
            }));
            this.byYear = resp.data;
            this.processing = false;
          }
        })
        .catch((e) => {
          this.messageProcessing = e.response.data.Message;
          this.processing = true;
        });
    },

    downloadReport() {
      const SheetNames = ["CortoLargo", "Detalle"];
      const DataTables = [this.items, this.byYear];
      const dataSend = { DataTables, SheetNames };

      this.processing = true;
      _sQryConfigurationService
        .downloadexcelrc(dataSend, this.$fun.getUserID())
        .then(
          (r) => {
            if (r.status == 200) {
              this.processing = false;

              this.$fun.downloadFile(
                r.data,
                this.$const.TypeFile.EXCEL,
                `Corto Largo ${this.period}`
              );
            }
          },
          (e) => {
            this.messageProcessing = e.response.data.Message;
            this.processing = true;
          }
        );
    },
  },
};
</script>

<style>
.tran {
  opacity: 0.7 !important;
}

/*  */
</style>
